.faq {
  display: flex;
  align-items: center;
  padding-top: 123px;
  flex-direction: column;
  justify-content: center;

  .faqTitle {
    font-size: 48px;
    font-weight: 900;
    line-height: 59px;
    text-align: center;
    padding-bottom: 46px;
    text-transform: uppercase;
    color: var(--white--text--default);
    text-shadow: 0px 4px 15px var(--shadow--default);
  }

  .questions {
    width: 900px;

    @media screen and (max-width: 1200px) {
      width: 700px;
    }
    @media screen and (max-width: 767px) {
      width: 310px;
    }
  }

  .question {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
    align-content: center;
    padding: 21px 25px 22px 51px;
    justify-content: space-between;
    background: var(--faq--dark_GRADIENT);

    &:hover {
      box-shadow: 0px 2px 10px var(--shadow--second);
    }

    @media screen and (max-width: 767px) {
      padding: 21px 25px 22px 35px;
    }
  }

  .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.65s ease-in-out;
  }

  .show {
    max-height: 350px;
    transition: max-height 0.65s ease-in-out;

    @media screen and (max-width: 767px) {
      max-height: 1000px;
    }
  }

  .faqText {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    color: var(--white--text);
  }

  .textAnswer {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    color: var(--white--text);
    padding: 0 25px 22px 51px;
  }

  .faqIcon {
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
    transition: transform 0.6s ease-in-out;
    filter: drop-shadow(4px 0px 17px var(--white--text--default));
  }

  .rotate {
    transform: rotate(270deg);
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 40px;
  }
}
