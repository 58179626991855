.messagesWrap {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  overflow-y: auto;
  text-align: center;
  scroll-behavior: smooth;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--border--gray--third);
  }

  @media (max-width: 1150px) {
    padding: 15px 55px 15px;
  }

  @media (max-width: 767px) {
    padding: 15px 15px 10px 35px;
  }

  @media (min-width: 1500px) {
    width: 1200px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 1300px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 1700px;
  }
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
}

// pre, code - index.scss
