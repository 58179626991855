.sm {
  width: 150px;
  height: 45px;

  @media screen and (max-width: 1200px) {
    width: 300px;
    height: 75px;
  }
}

.sm-dlg {
  width: 240px;
  height: 30px;

  @media (max-width: 1200px) {
    width: 220px;
  }
  @media (max-width: 1150px) {
    width: 270px;
  }
  @media (max-width: 767px) {
    width: 220px;
  }
  @media (min-height: 1000px) {
    height: 60px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 330px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 400px;
    height: 70px;
  }
}

.md {
  // width: 200px;
  // height: 45px;
  width: 234px;
  height: 40px;
}

.md-dlg {
  width: 230px;
  min-height: 45px;

  @media (max-width: 1200px) {
    width: 200px;
  }
  @media (max-width: 1150px) {
    width: 270px;
  }
  @media (max-width: 767px) {
    width: 200px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 300px;
    height: 60px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 400px;
    height: 70px;
  }
}

.md-lg {
  width: 238px;
  height: 55px;

  @media screen and (max-width: 1000px) {
    width: 230px;
    height: 46px;
  }
}

.lg {
  width: 330px;
  height: 73px;

  @media screen and (max-width: 767px) {
    width: 270px;
  }
}

.xl {
  width: 375px;
  height: 55px;

  @media screen and (max-width: 767px) {
    width: 300px;
    height: 60px;
  }
}

.default {
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--main);
  border: 1px var(--button--background--main) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }
}

.newDialog {
  display: flex;
  margin-top: 38px;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--main);
  border: 1px var(--button--background--main) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--menu--dark--background--opacity);
  }

  @media (max-width: 1150px) {
    margin-top: 65px;
  }
}

.setting {
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  border: 1px var(--border--gray--fifth) solid;
  background: var(--border--gray--fourth);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--menu--dark--background--opacity);
  }
}

.autorization {
  margin-top: 28px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--main);
  border: 1px var(--button--background--main) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }
}

.error {
  margin-top: 36px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--first);
  border: 1px var(--button--background--second) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }
}

.profile {
  padding: 14px 45px;
  border-radius: 10px;
  margin: 67px 0 0 65px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--premium);
  border: 1px var(--button--background--premium--first) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }

  @media screen and (max-width: 1215px) {
    margin: 65px 98px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px 0 25px;
    margin: 25px 0;
  }
}

.profileExit {
  padding: 14px 45px;
  border-radius: 10px;
  margin: 35px 0 0 35px;
  transition: all 0.3s ease-in-out;
  background: var(--button--background--premium);
  border: 1px var(--button--background--premium--first) solid;
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }

  @media screen and (max-width: 1215px) {
    display: flex;
    margin: 40px auto 0;
  }

  @media screen and (max-width: 767px) {
    padding: 17px 20px;
  }
}

.defaultText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-transform: lowercase;
  color: var(--white--text--default);

  @media screen and (max-width: 767px) {
    font-size: 21px;
  }
}

.headerText {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: var(--white--text--default);

  @media screen and (max-width: 1200px) {
    font-size: 25px;
  }
}

.priceText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: lowercase;
  color: var(--white--text--default);
}

.authorizationText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: lowercase;
  color: var(--white--text--default);

  @media screen and (max-width: 1200px) {
    font-size: 17px;
  }
}

.errorText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-transform: lowercase;
  color: var(--white--text--default);

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
}

.profileText {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  line-height: 24px;
  text-transform: lowercase;
  color: var(--white--text--default);

  @media screen and (max-width: 767px) {
    font-size: 21px;
  }
}

.profileExitText {
  gap: 10px;
  display: flex;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  text-transform: lowercase;
  color: var(--white--text--default);

  @media screen and (max-width: 767px) {
    font-size: 21px;
  }
}

.premiumProfileText {
  gap: 10px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 25px;
  align-items: center;
  color: var(--white--text--default);
}

.newDialogText {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 16px;
  color: var(--white--text--default);

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 24px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 26px;
    line-height: 26px;
  }
}

.settingText {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  align-items: center;
  text-transform: lowercase;
  color: var(--light-grey--text);

  @media (min-height: 1000px) {
    font-size: 18px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 22px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 24px;
    line-height: 24px;
  }
}
