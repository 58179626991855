.sendMessageInput {
  width: 700px;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
  overflow-y: auto;
  line-height: 20px;
  border-radius: 10px;
  scroll-behavior: smooth;
  padding: 10px 48px 10px 17px;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;
  background: var(--message--input--background);
  box-shadow: 0px 4px 15px var(--shadow--first);

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--border--gray--default);
  }

  &:focus,
  &:focus-visible {
    outline: 0;
    box-shadow: 0px 4px 15px var(--border--gray--default);
  }

  @media (min-width: 1500px) {
    min-height: 60px;
    width: 1100px;
    font-size: 20px;
  }

  @media (max-width: 767px) {
    width: 250px;
    margin-bottom: 40px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 1300px;
    font-size: 22px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 1700px;
    font-size: 24px;
    line-height: 24px;
  }
}
