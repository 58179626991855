.edyaFunctions {
  margin-top: 100px;
  background-color: var(--main--dark_GRADIENT);

  .headerFunctions {
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 130px;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      margin-bottom: 70px;
    }
  }

  .headerFuncWrap {
    display: flex;
    position: relative;
    align-items: center;
  }

  .funcLogo {
    z-index: 1;
    top: -19px;
    left: -45px;
    width: 90px;
    height: 86px;
    opacity: 40%;
    position: absolute;

    @media screen and (max-width: 767px) {
      left: 0px;
      top: -9px;
      width: 74px;
      height: 70px;
    }
  }

  .funcTitle {
    z-index: 100;
    font-size: 50px;
    font-weight: 900;
    line-height: 61px;
    letter-spacing: 0.01em;
    color: var(--white--text);
    text-transform: uppercase;
    text-shadow: 0px 4px 15px var(--shadow--default);

    @media screen and (max-width: 767px) {
      font-size: 35px;
    }
  }

  .funcSubtitle {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 10px;
    letter-spacing: 0.01em;
    color: var(--light-grey--text);

    @media screen and (max-width: 1200px) {
      width: 745px;
    }

    @media screen and (max-width: 767px) {
      width: 310px;
      font-size: 21px;
      line-height: 30px;
    }
  }

  .leftBlock {
    gap: 40px;
    display: flex;
    margin-bottom: 175px;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      align-items: center;
      margin-bottom: 100px;
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-bottom: 70px;
    }
  }

  .leftBlockText {
    width: 690px;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      width: 85%;
      display: flex;
      font-size: 21px;
      align-items: center;
    }
  }

  .leftTitle {
    font-size: 48px;
    font-weight: 900;
    line-height: 59px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--white--text--default);
    border-bottom: 1px var(--light-grey--text) solid;

    @media screen and (max-width: 767px) {
      font-size: 27px;
      line-height: 40px;
    }
  }

  .leftSubtitle {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
    color: var(--light-grey--text);

    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }

  .blockImg {
    width: 440px;
    height: 258px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 4px 15px var(--shadow--default);

    @media screen and (max-width: 767px) {
      width: 85%;
      height: 85%;
    }
  }

  .rightBlock {
    gap: 45px;
    display: flex;
    margin-bottom: 175px;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      align-items: center;
      flex-direction: column;
      margin-bottom: 100px;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-bottom: 70px;
    }
  }

  .rightBlockText {
    width: 690px;
    text-align: end;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      width: 85%;
      display: flex;
      font-size: 21px;
      align-items: center;
    }
  }

  .rightTitle {
    font-size: 48px;
    font-weight: 900;
    line-height: 59px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--white--text--default);
    border-bottom: 1px var(--light-grey--text) solid;

    @media screen and (max-width: 767px) {
      font-size: 27px;
      line-height: 40px;
    }
  }

  .rightSubtitle {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
    color: var(--light-grey--text);

    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }
}
