.formGroup {
  display: flex;
  align-items: center;
  margin: 17px 10px 0 10px;
}

.checkboxLabel {
  gap: 8px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  text-align: start;
  align-items: center;
  text-transform: lowercase;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.customCheckbox {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.customCheckbox {
  margin: 0;
  width: 11px;
  height: 11px;
  user-select: none;
  align-items: center;
  display: inline-flex;

  @media (max-width: 1200px) {
    width: 14px;
    height: 14px;
  }
}

.customCheckbox + label::before {
  width: 9px;
  height: 9px;
  content: '';
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 2px;
  display: inline-block;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid var(--border--gray--third);

  @media (max-width: 1200px) {
    width: 12px;
    height: 12px;
  }
}
.customCheckbox:checked + label::before {
  border-color: var(--custom--checkbox--blue);
  background-size: 70% 70%;
  background-color: var(--custom--checkbox--blue);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.customCheckbox:not(:disabled):not(:checked) + label:hover::before {
  border: 1px var(--white--text--default) solid;
  box-shadow: 0px 4px 15px var(--border--gray--default);
}
.customCheckbox:not(:disabled):active + label::before {
  border: 1px var(--white--text--default) solid;
  box-shadow: 0px 4px 15px var(--border--gray--default);
}
.customCheckbox:disabled + label::before {
  border: 1px var(--white--text--default) solid;
}

.errorMessage {
  width: 400px;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 17px;
  text-transform: lowercase;
  color: var(--icon--box-shadow--red);

  @media (max-width: 767px) {
    width: 250px;
  }
}
