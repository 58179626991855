.standart {
  height: 500px;
}

.small {
  height: 300px;
}

.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
  margin: 75px auto;
  position: relative;

  div {
    height: 100%;
  }
}

.loaderItem,
.loaderItem div {
  padding: 8px;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: rotate linear 3.5s infinite;
  border-bottom-color: var(--button--background--main);
  border-top-color: var(--white--text--default--opacity);
}

div:hover {
  animation-play-state: paused;
}

.loader,
.loader * {
  will-change: transform;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: var(--white--text--default--opacity);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: var(--button--background--main);
  }
  100% {
    transform: rotate(360deg);
    border-top-color: var(--white--text--default--opacity);
  }
}
