.distinctions {
  padding-top: 12px;

  @media screen and (max-width: 1200px) {
    padding-top: 30px;
  }

  .title {
    font-size: 48px;
    font-weight: 900;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--white--text--default);
    text-shadow: 0px 4px 15px var(--shadow--default);

    @media screen and (max-width: 767px) {
      font-size: 35px;
    }
  }

  .wrapper {
    gap: 170px;
    display: flex;
    position: relative;
    padding-top: 131px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      gap: 0;
      padding-top: 100px;
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }

  .line {
    z-index: 1;
    bottom: 60px;
    height: 790px;
    position: absolute;
    border-left: 1px solid var(--white--text--default);

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .leftLine {
    left: 70px;

    @media screen and (max-width: 1200px) {
      top: 228px;
      left: 200px;
    }
  }

  .rightLine {
    right: 70px;

    @media screen and (max-width: 1200px) {
      right: 211px;
    }
  }

  .edya {
    width: 483px;
    z-index: 100;

    @media screen and (max-width: 767px) {
      width: 310px;
    }
  }

  .logoEdya {
    width: 140px;
    height: 135px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  .chatGPT {
    width: 460px;
    z-index: 100;
    text-align: right;

    @media (max-width: 767px) {
      width: 310px;
    }
  }

  .logoChat {
    width: 140px;
    height: 135px;
    margin-left: 21px;

    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
  }

  .display {
    align-items: center;
    margin-bottom: 140px;
    display: inline-flex;

    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      padding: 0 5px;
    }
  }

  .logoBlock {
    margin-bottom: 70px;

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
    position: relative;

    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
  }

  .check {
    margin-right: 26px;
    border-radius: 50%;
    box-shadow: 0px 4px 15px var(--icon--box-shadow--green);

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .cross {
    margin-left: 26px;
    border-radius: 50%;
    box-shadow: 0px 4px 15px var(--icon--box-shadow--red);

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      margin-left: 0;
    }
  }

  .leftBlock {
    padding-left: 45px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }
  .rigthBlock {
    padding-right: 45px;

    .display {
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }

    @media screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .text {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: var(--white--text--default);
  }
}
