.footer {
  padding-top: 70px;
}

.footerRow {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 45px;
  justify-content: space-between;
  border-bottom: 1px solid var(--border--gray--third);
}

.footerCollumn {
  width: 25%;
  padding: 0 20px;

  .footerTitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    position: relative;
    margin-bottom: 35px;
    color: var(--white--text--default);
  }

  .footerTitle::before {
    left: 0;
    height: 2px;
    width: 50px;
    content: '';
    bottom: -10px;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--button--background--main);
  }
}

.footerCollumn ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footerCollumn ul li a {
  display: block;
  font-size: 20px;
  line-height: 24px;
  transition: all 0.3s ease;
  color: var(--light-grey--text);
}

.footerCollumn ul li a:hover {
  padding-left: 8px;
  color: var(--white--text);
}

.payWrap {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    row-gap: 15px;
    display: grid;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 70px 70px;
  }
}

.payImgCard {
  height: 35px;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.payImgQiwi {
  height: 35px;
}

.telegram {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--white--text);
  transition: all 0.3s ease;
  border: 1px var(--button--background--main) solid;
  background-color: var(--button--background--main);
  box-shadow: 0px 4px 15px var(--button--box-shadow--default);
}

.telegram:hover {
  transition: all 0.3s ease;
  border: 1px var(--button--border--hover) solid;
  background-color: var(--button--background--hover);
}

.footerLogo {
  width: 170px;
  height: 56px;
  margin-top: 30px;
  filter: drop-shadow(0px 4px 10px var(--shadow--default));
}

.copyright {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  padding: 13px 0 14px;
  text-transform: lowercase;
  color: var(--light-grey--text);
}

.copyrightLink {
  color: var(--light-grey--text);

  &:hover {
    color: var(--white--text);
  }
}

@media (max-width: 1200px) {
  .footerCollumn {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding-top: 50px;
  }

  .footerCollumn {
    width: 100%;

    .footerTitle::before {
      left: 86px;
      height: 2px;
      width: 100px;
      content: '';
      bottom: -10px;
      position: absolute;
      box-sizing: border-box;
      background-color: var(--button--background--main);
    }
  }

  .footerRow {
    text-align: center;
    padding-bottom: 15px;
  }
}
