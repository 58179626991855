.notFoundPage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  color: var(--white--text--default);
}

.notFoundWrap {
  z-index: 100;
  height: 100vh;
  position: relative;
  padding: 10% 0 0 5%;

  @media (max-width: 1200px) {
    padding: 10% 20% 0 7%;
  }
  @media (max-width: 1000px) {
    padding: 10% 5% 0 5%;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}

.notFoundTitle {
  font-weight: 700;
  font-size: 110px;
  line-height: 134px;
  text-transform: uppercase;
  color: var(--white--text--default);

  @media (max-width: 1300px) {
    font-size: 80px;
    line-height: 100px;
  }
  @media (max-width: 1000px) {
    font-size: 70px;
    line-height: 90px;
  }
  @media (max-width: 767px) {
    font-size: 45px;
    line-height: 60px;
  }
}

.notFoundSubtitle {
  font-size: 35px;
  font-weight: 400;
  line-height: 43px;
  text-transform: lowercase;
  color: var(--light-grey--text);

  @media (max-width: 1300px) {
    font-size: 25px;
    line-height: 33px;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.iconError {
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: 2500px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 50vh;
  }
}
