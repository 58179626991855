.bodyNavigationOpen {
  height: 100%;
  overflow: hidden;
}

.headerMain {
  height: 20vh;
  display: flex;
  flex-direction: column;
}

.header {
  z-index: 101;
  display: flex;
  padding-top: 54px;
  position: relative;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 170px;
    height: 56px;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
      margin-left: 40px;
    }

    @media screen and (max-width: 767px) {
      width: 150px;
      height: 50px;
      margin-left: 10px;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
      top: 0;
      gap: 40px;
      left: -350px;
      width: 200px;
      height: 100vh;
      position: fixed;
      transition: all 0.3s ease-in-out;
      background-color: var(--main--dark--background);
    }
  }

  .navigationList {
    gap: 25px;
    display: flex;
    margin-right: 65px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .navigationLink {
    padding: 10px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;

    @media (max-width: 1200px) {
      margin: 10px 0;
      font-size: 22px;
      line-height: 24px;
    }

    @media (min-width: 1600px) {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .buttonWrap {
    gap: 20px;
    display: flex;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .loaderWrap {
    width: 320px;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media screen and (max-width: 1200px) {
      margin-left: 30px;
      flex-direction: none;
      justify-content: center;
    }
  }

  .userInfo {
    width: 320px;
    height: 50px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1200px) {
      display: none;
      margin-left: 0;
    }
  }

  .userName {
    text-align: end;
    font-size: 20px;
    font-weight: 900;
    overflow: hidden;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: var(--white--text--default);
  }

  .avatarImg {
    height: 45px;
    margin-left: 25px;
    border-radius: 50%;
  }

  .avatarUser {
    width: 45px;
    height: 45px;
    object-fit: cover;
    margin-left: 20px;
    border-radius: 50%;
    box-shadow: 0px 4px 20px var(--shadow--default);
  }

  .userNavigation {
    right: 0;
    top: 49px;
    width: 250px;
    position: absolute;
    border-radius: 10px;
    background: var(--authorization--bg_GRADIENT);
    box-shadow: 0px 4px 20px var(--shadow--default);
  }

  .userNavigation.userNavigationTablet {
    top: auto;
    left: 25px;
    bottom: 100px;
  }

  .userNavigationText {
    padding: 20px;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    color: var(--white--text--default);
    border-bottom: 1px solid var(--light-grey--text);

    &:last-of-type {
      border: none;
    }

    &:hover {
      background-color: var(--button--border--hover);

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .burger {
      right: 50px;
      z-index: 1;
      width: 30px;
      height: 20px;
      display: block;
      cursor: pointer;
      position: relative;

      @media (max-width: 767px) {
        right: 20px;
      }
    }

    .burger span {
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: var(--white--text--default);
      transition: transform 0.3s ease-in-out;
    }

    .burger span:nth-child(1) {
      top: 0;
    }

    .burger span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    .burger span:nth-child(3) {
      bottom: 0;
    }

    .burger.navigationOpen span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 7px);
    }

    .burger.navigationOpen span:nth-child(2) {
      opacity: 0;
    }

    .burger.navigationOpen span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -7px);
    }

    .navigation.navigationOpen {
      left: 0;
      width: 100%;
      display: flex;
      padding-top: 50px;
      flex-direction: column;
    }

    .navigationList.navigationOpen {
      margin: 0;
      gap: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .navigationList.userMenuOpen {
      visibility: hidden;
    }

    .buttonWrap.navigationOpen {
      gap: 40px;
      display: flex;
      flex-direction: column;
    }

    .userInfo.navigationOpen {
      display: flex;
      justify-content: center;
    }
  }
}
