.contractOffer {
  margin: auto;
  display: flex;
  max-width: 1100px;
  flex-direction: column;
  color: var(--white--text--default);
}

.contractTitle {
  font-size: 45px;
  font-weight: 900;
  line-height: 50px;
  padding-top: 40px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--white--text);
  text-shadow: 0px 4px 15px var(--shadow--default);

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 30px;
  }
}

.contractWrap {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  padding-top: 65px;
  text-align: justify;
  letter-spacing: 0.01em;
  font-variant: small-caps;
  white-space: break-spaces;
  color: var(--white--text--default);

  @media (max-width: 1200px) {
    font-size: 22px;
    padding: 30px 50px 0;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    padding: 30px 0 30px;
  }
}
