.dialogArea {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3%;
  align-items: center;
  flex-direction: column;

  @media (max-height: 700px) {
    padding-top: 1%;
  }
}

.sendMessageWrap {
  gap: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    gap: 10px;
  }
}

.sendMessage {
  display: flex;
  position: relative;
  align-items: center;
  margin: 10px 0 40px;
  justify-content: center;

  @media (max-width: 767px) {
    margin: 0;
  }
}

.sendMessageBtn {
  right: 0;
  bottom: 0;
  height: 100%;
  border: none;
  position: absolute;
  padding: 20px 16px 0;
  color: var(--white--text--default);
  background-color: transparent;

  :hover {
    filter: drop-shadow(0px 0px 2px var(--button--background--main));
  }

  @media (max-width: 767px) {
    padding: 0 10px 20px 10px;
  }
}

.sendMessageImg {
  width: 20px;
  height: 20px;

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 28px;
    height: 28px;
  }
}
