.avatarBlockWrap {
  gap: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avatarBlock {
  width: 540px;
  height: 442px;
  border-radius: 10px;
  border: 1px solid var(--light-grey--text);
  background: var(--avatar--dark--background);
  box-shadow: 0px 4px 10px var(--shadow--default);

  @media screen and (max-width: 767px) {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
}

.premiumImg {
  width: 15px;
  height: 15px;
  filter: drop-shadow(0px 0px 10px var(--shadow--star));
}

.edyaImg {
  width: 18px;
  height: 18px;
  filter: drop-shadow(0px 0px 10px var(--shadow--default));
}

.infoWrap {
  margin: 15px 90px 0 90px;

  @media screen and (max-width: 767px) {
    margin: 0;
    display: flex;
    margin: 0 auto;
    align-items: center;
    word-break: break-all;
    flex-direction: column;
  }
}

.userName {
  font-size: 20px;
  overflow: hidden;
  font-weight: 900;
  line-height: 24px;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: var(--white--text--default);

  @media (max-width: 767px) {
    max-width: 265px;
  }
}

.premiumWrap {
  gap: 10px;
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.premiumText {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;

  color: var(--white--text);
}

.premiumImg {
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
  filter: drop-shadow(0px 0px 10px var(--shadow--star));
}
