@import 'reset.scss';
@import 'fonts.scss';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue;
  font-style: normal;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-color: var(--main--dark--background);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--white--text--default);
  &:visited {
    text-decoration: none;
  }
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
  &:hover {
    cursor: pointer;
  }
}

.hljs-comment {
  color: #a5a5a5;
}

pre,
code {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  border-radius: 10px;
  white-space: pre-wrap;
  color: var(--code--text);
  margin: 30px 0 40px 0 !important;
  font-family: 'Courier New', Courier, monospace !important;

  @media (min-width: 1400px) {
    font-size: 22px;
    line-height: 25px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 26px;
    line-height: 29px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 28px;
    line-height: 31px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.svg-inline--fa {
  height: 1.2em;
}

.copyButton {
  width: 180px;
  height: 30px;
  border: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;
  background: var(--message--input--background);
  box-shadow: 0px 4px 15px var(--shadow--first);
  border: 1px var(--button--border--hover-second) solid;

  &:hover {
    transition: all 0.3s ease-in-out;
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }

  @media (min-width: 1400px) {
    width: 200px;
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 280px;
    height: 40px;
    font-size: 24px;
    line-height: 28px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 300px;
    height: 45px;
    font-size: 26px;
    line-height: 30px;
  }
}

:root {
  --avatar--dark--background: #242424;
  --main--dark--background: #1c1c1c;
  --menu--dark--background: rgb(36, 36, 36);
  --menu--dark--background--opacity: rgba(36, 36, 36, 0.7);
  --main--dark_GRADIENT: linear-gradient(
    348.05deg,
    #1d1d1d 0%,
    rgba(29, 29, 29, 0.943113) 33.71%,
    rgba(29, 29, 29, 0.913315) 62.07%,
    rgba(29, 29, 29, 0.890625) 89.11%,
    rgba(29, 29, 29, 0.744792) 102.03%,
    rgba(29, 29, 29, 0.572917) 102.04%,
    rgba(29, 29, 29, 0.390625) 102.05%,
    rgba(29, 29, 29, 0.1875) 102.06%,
    rgba(29, 29, 29, 0.104167) 102.07%,
    rgba(29, 29, 29, 0.0377194) 102.08%,
    rgba(29, 29, 29, 0.00411201) 102.09%,
    rgba(29, 29, 29, 0) 102.73%
  );
  --faq--dark_GRADIENT: radial-gradient(
    105.11% 17375.51% at 100% 100%,
    rgba(217, 217, 217, 0.1) 0%,
    rgba(217, 217, 217, 0.078125) 68.34%,
    rgba(217, 217, 217, 0) 100%
  );
  --price--card_GRADIENT: linear-gradient(
    329.11deg,
    rgba(179, 179, 179, 0) -6.53%,
    rgba(179, 179, 179, 0.25625) -6.52%,
    rgba(179, 179, 179, 0.111) -6.51%,
    rgba(244, 244, 244, 0.3) -4.98%,
    rgba(223, 223, 223, 0.249) -4.61%,
    rgba(196, 196, 196, 0.186) 15.13%,
    rgba(189, 189, 189, 0.147) 48.77%,
    rgba(179, 179, 179, 0.135) 71.9%,
    rgba(179, 179, 179, 0.132) 102.99%,
    rgba(209, 209, 209, 0.195) 104.68%,
    rgba(179, 179, 179, 0.093) 106.93%
  );
  --authorization--bg_GRADIENT: linear-gradient(
    109.25deg,
    #393939 0%,
    #393939 12.25%,
    #393939 23.44%,
    #393939 35.7%,
    rgba(57, 57, 57, 0.95082) 59.67%,
    rgba(57, 57, 57, 0.894934) 81.52%,
    rgba(57, 57, 57, 0.83969) 102.29%,
    rgba(57, 57, 57, 0) 102.3%
  );
  --example--background_GRADIENT: linear-gradient(
    273.35deg,
    rgba(179, 179, 179, 0.15) -11.34%,
    rgba(179, 179, 179, 0.132813) -4.83%,
    rgba(179, 179, 179, 0.0102382) 81.91%,
    rgba(179, 179, 179, 0) 108.82%
  );
  --message--input--background: rgba(68, 31, 218, 0.3);
  --message--background_GRADIENT: linear-gradient(
    273.35deg,
    rgba(179, 179, 179, 0.15) -11.34%,
    rgba(179, 179, 179, 0.132813) -4.83%,
    rgba(179, 179, 179, 0.0102382) 81.91%,
    rgba(179, 179, 179, 0) 108.82%
  );
  --message--background_GRADIENT_second: linear-gradient(
    93.35deg,
    rgba(179, 179, 179, 0.15) -11.34%,
    rgba(179, 179, 179, 0.132813) -4.83%,
    rgba(179, 179, 179, 0.0102382) 81.91%,
    rgba(179, 179, 179, 0) 108.82%
  );

  --button--background--main: #441fda;
  --button--background--first: rgba(68, 31, 218, 0.3);
  --button--background--second: rgba(68, 31, 218, 0.05);
  --button--background--premium: rgba(68, 31, 218, 0.3);
  --button--background--premium--first: rgba(68, 31, 218, 0.05);
  --button--background--dark--main: #291d56;

  --button--background--hover: rgba(244, 244, 244, 0.06);
  --button--border--hover: rgba(244, 244, 244, 0.2);
  --button--border--hover-second: rgba(68, 31, 218, 0.03);

  --button--box-shadow--default: rgba(115, 97, 185, 0.25);

  --light-grey--text: #b3b3b3;
  --white--text--default: #f4f4f4;
  --white--text--default--opacity: rgba(244, 244, 244, 0.69);
  --white--text: #ffffff;
  --code--text: #b5cfff;
  --telemram--main: #229ed9;

  --shadow--default: rgba(244, 244, 244, 0.5);
  --shadow--second: rgba(179, 179, 179, 0.68);
  --shadow--third: rgba(244, 244, 244, 0.62);
  --shadow--autho: rgba(0, 0, 0, 0.25);
  --shadow--star: rgba(255, 244, 0, 0.5);

  --border--gray--default: rgba(179, 179, 179, 0.26);
  --border--gray--first: rgba(179, 179, 179, 0.3);
  --border--gray--second: rgba(179, 179, 179, 0.42);
  --border--gray--third: rgba(179, 179, 179, 0.5);
  --border--gray--fourth: rgba(179, 179, 179, 0.22);
  --border--gray--fifth: rgba(179, 179, 179, 0.05);

  --icon--box-shadow--green: #8be423;
  --icon--box-shadow--red: #fc3d24;

  --custom--checkbox--blue: #0b76ef;

  --for-all--error: #ff0000;
}
