.mainBanner {
  margin: auto;
  height: 80vh;
  z-index: 100;
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 767px) {
    width: 310px;
  }

  .title {
    font-weight: 900;
    font-size: 150px;
    line-height: 183px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--white--text--default);
    text-shadow: 2px 2px 15px var(--shadow--second);

    @media screen and (max-width: 767px) {
      font-size: 90px;
    }
  }

  .subtitle {
    height: 54px;
    font-size: 35px;
    font-weight: 700;
    line-height: 43px;
    text-transform: uppercase;
    color: var(--light-grey--text);

    @media screen and (max-width: 767px) {
      font-size: 25px;
      margin-bottom: 40px;
    }
  }

  .description {
    height: 45px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 48px;
    color: var(--white--text--default--opacity);

    @media screen and (max-width: 1200px) {
      width: 80%;
      font-size: 17px;
      margin-bottom: 80px;
    }
  }
}
