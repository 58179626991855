.successfulPayment {
  height: 50vh;
  display: flex;
  margin: 40px 0;
  min-height: 400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
  }
}

.title {
  font-size: 40px;
  font-weight: 800;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white--text);
  text-shadow: 0px 4px 15px var(--shadow--default);

  @media (max-width: 767px) {
    font-size: 30px;
    padding: 0 15px;
  }
}

.info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  font-size: 26px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  padding: 60px 80px 0;
  letter-spacing: 0.01em;
  color: var(--white--text--default);

  &__accent {
    font-weight: 600;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
    color: var(--button--background--main);
  }

  &__accent:hover {
    transition: all 0.3s ease-in-out;
    color: var(--custom--checkbox--blue);
  }

  @media (max-width: 1200px) {
    font-size: 24px;
    padding: 30px 50px 0;
  }
}
