.burger {
  @media (max-width: 1150px) {
    top: 20px;
    left: 10px;
    width: 35px;
    height: 20px;
    z-index: 100;
    display: block;
    cursor: pointer;
    position: fixed;
  }

  @media (max-width: 900px) {
    width: 30px;
  }
  @media (max-width: 767px) {
    left: 5px;
    width: 25px;
  }
}

.burger span {
  @media (max-width: 1150px) {
    width: 100%;
    height: 3px;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    background-color: var(--white--text--default);
  }
}

.burger span:nth-child(1) {
  @media (max-width: 1150px) {
    top: 0;
  }
}

.burger span:nth-child(2) {
  @media (max-width: 1150px) {
    top: 50%;
    transform: translateY(-50%);
  }
}

.burger span:nth-child(3) {
  @media (max-width: 1150px) {
    bottom: 0;
  }
}

.burger.navigationOpen span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 7px);
}

.burger.navigationOpen span:nth-child(2) {
  opacity: 0;
}

.burger.navigationOpen span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -7px);
}

.md {
  width: 230px;
  min-height: 45px;

  @media (max-width: 1200px) {
    width: 200px;
  }
  @media (max-width: 1150px) {
    width: 270px;
  }
  @media (max-width: 767px) {
    width: 200px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 300px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 400px;
    height: 70px;
  }
}

.setting {
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: var(--border--gray--fourth);
  border: 1px var(--border--gray--fifth) solid;

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--menu--dark--background--opacity);
  }
}

.dialogBox {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.settings {
  height: 100%;
  min-width: 270px;
  background-color: var(--menu--dark--background--opacity);

  @media (min-width: 1400px) {
    min-width: 300px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    min-width: 450px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    min-width: 500px;
  }

  @media (max-width: 1200px) {
    min-width: 230px;
  }

  @media (max-width: 1150px) {
    z-index: 99;
    left: -300px;
    height: 100vh;
    position: fixed;
    min-width: 300px;
    transition: all 0.3s ease-in-out;
    background-color: var(--menu--dark--background);
  }
}

.settings.navigationOpen {
  @media (max-width: 1150px) {
    left: 0;
    min-width: 300px;
  }
  @media (max-width: 767px) {
    left: 0;
    min-width: 250px;
  }
}

.settingNewChat {
  display: flex;
  height: 80%;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--border--gray--third);

  @media (max-height: 500px) {
    height: 70%;
  }
}

.chatsWrap {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 5px;
  overflow-y: auto;
  text-align: center;
  align-items: center;
  padding-bottom: 15px;
  flex-direction: column;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--border--gray--fourth);
  }
}

.dialogWrap {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow-y: auto;
  text-align: center;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--border--gray--default);
  }

  @media (max-width: 1150px) {
    padding: 15px 55px 15px;
  }

  @media (max-width: 767px) {
    padding: 15px 15px 10px 35px;
  }

  @media (min-width: 1500px) {
    width: 1200px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 1300px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 1700px;
  }
}

.iconDialog {
  width: 40px;
  align-self: flex-start;
  margin: 15px 15px 15px 0;
  filter: drop-shadow(0px 0px 5px var(--shadow--default));

  @media (max-width: 767px) {
    margin: 18px 10px 18px 0;
  }

  @media (min-width: 1400px) {
    width: 50px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 65px;
  }
}

.messageBox {
  width: 100%;
  height: auto;
  display: flex;
  margin: 20px 0;
  padding: 0 10px;
  border-radius: 10px;
  align-items: center;

  &:nth-child(odd) {
    background: var(--message--background_GRADIENT);
  }
  &:nth-child(even) {
    background: var(--message--background_GRADIENT_second);
  }

  @media (max-width: 767px) {
    .iconDialog {
      width: 30px;
    }

    .messageText {
      min-width: 85%;
      padding-right: 10px;
      word-wrap: break-word;
    }
  }
}

.messageText {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 18px;
  margin: 18px 5px 18px 0;

  color: var(--white--text--default);

  @media (min-width: 1400px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 26px;
    line-height: 28px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 28px;
    line-height: 30px;
  }
}
