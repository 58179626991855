.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1187px;
  margin: 0 auto;
  min-height: 1000px;
  color: var(--white--text--default);

  @media screen and (max-width: 1215px) {
    width: 766px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 790px) {
    width: 310px;
  }
}

.userInfo {
  gap: 70px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;

  @media screen and (max-width: 1215px) {
    gap: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.basicInfoWrap {
  @media screen and (max-width: 1215px) {
    display: flex;
    margin-top: 25px;
    margin-bottom: 0;
    align-items: center;
    flex-direction: column;
  }
}

.basicInfo {
  @media screen and (max-width: 1215px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.statistics {
  width: 540px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--light-grey--text);

  @media screen and (max-width: 767px) {
    width: 300px;
    height: 100%;
    margin-top: 25px;
  }
}

.statisticsString {
  display: flex;
  padding: 20px 30px 15px;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey--text);

  &:last-of-type {
    border: none;

    &::before {
      border: none;
    }
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
}

.statisticsText {
  filter: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: lowercase;
  color: var(--white--text--default);
}

.statisticsValue {
  filter: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--white--text--default);

  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}

.buttons {
  margin-bottom: 50px;

  @media screen and (max-width: 1215px) {
    display: flex;
    margin: 0 0 50px;
    align-items: center;
    flex-direction: column;
  }
}
