.wrapperImageUploader {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  margin: 35px 120px 0;
}

.avatarImg {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.avatarImg:hover {
  transition: all 0.2s ease-in-out;
  outline: 1px solid var(--shadow--default);
  box-shadow: 0 0px 20px var(--shadow--default);
}

@media screen and (max-width: 767px) {
  .wrapperImageUploader {
    margin: 0;
    padding: 0;
  }
  .avatarImg {
    width: 280px;
    padding: 10px;
  }
  .iconXmark {
    right: 5px;
  }
}
