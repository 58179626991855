.dialogs {
  top: 65%;
  left: 23%;
  position: absolute;
}

.messages {
  margin-top: 20px;
  margin-left: 60px;
}

.default {
  transform: none;
}

.form {
  justify-content: center;
  transform: none !important;
}

.loaderDots {
  display: flex;
  transform: translate(-50%, -50%);
}

.defaultDots {
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 40%;
  background: var(--button--background--main);
  transform: scale(0.5);
  animation: scale-dot 1.2s ease-out infinite;
  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.4s;
  }
  &:nth-of-type(5) {
    animation-delay: 0.5s;
  }
}
.formDots {
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 40%;
  background: var(--white--text);
  transform: scale(0.5);
  animation: scale-dot 1.2s ease-out infinite;
  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.4s;
  }
  &:nth-of-type(5) {
    animation-delay: 0.5s;
  }
}

@keyframes scale-dot {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.5);
  }
}
