/* Montserrat-regular */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local(''),
    url('../../public/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../../public/fonts/Montserrat-Regular.woff') format('woff');
}
/* Montserrat-medium */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: local(''),
    url('../../public/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../../public/fonts/Montserrat-Medium.woff') format('woff');
}
/* Montserrat-semiBold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local(''),
    url('../../public/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../../public/fonts/Montserrat-SemiBold.woff') format('woff');
}
/* Montserrat-bold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local(''),
    url('../../public/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../../public/fonts/Montserrat-Bold.woff') format('woff');
}
/* Montserrat-black */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: local(''),
    url('../../public/fonts/Montserrat-Black.woff2') format('woff2'),
    url('../../public/fonts/Montserrat-Black.woff') format('woff');
}
/* CourierStd */
@font-face {
  font-family: 'Courier';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local(''), url('../../public/fonts/CourierStd.woff2') format('woff2'),
    url('../../public/fonts/CourierStd.woff') format('woff');
}
