.buttonGeneratorWrap {
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: relative;
  margin-bottom: 11px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background: var(--message--input--background);
  box-shadow: 0px 4px 15px var(--shadow--first);
  border: 1px var(--button--border--hover-second) solid;

  &:hover {
    transition: all 0.3s ease-in-out;
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }
  &:active {
    transition: all 0.3s ease-in-out;
    border: 1px var(--button--border--hover) solid;
    background-color: var(--button--background--hover);
  }

  @media (min-width: 1500px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 21px;
  }
}

.buttonGeneratorWrap::before {
  left: 0;
  opacity: 0;
  bottom: 45px;
  padding: 5px;
  cursor: default;
  font-size: 12px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  transform: translateY(0px);
  color: var(--white--text--default);
  background: var(--button--background--main);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media (min-width: 1400px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (min-width: 1500px) {
    bottom: 60px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 26px;
    line-height: 28px;
  }
}

.imageGenerator::before {
  content: 'генерация изображений';
}
.textGenerator::before {
  content: 'генерация текста';
}

.buttonGeneratorWrap:hover::before {
  opacity: 1;
  transform: translateY(-10px);

  @media (max-width: 767px) {
    display: none;
  }
}

.buttonGenerator {
  height: 20px;
  color: var(--white--text--default);

  @media (min-width: 1500px) {
    height: 30px;
  }
}

.neuronetBurgers {
  left: 0;
  bottom: 50px;
  width: 200px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 4px 15px var(--shadow--first);
  background: var(--button--background--dark--main);

  @media (min-width: 1500px) {
    bottom: 70px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 250px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 265px;
  }
}

.neuronet {
  padding: 10px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  color: var(--white--text--default);

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 22px;
    line-height: 24px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 24px;
    line-height: 26px;
    min-height: 75px;
  }

  &:first-of-type {
    border-bottom: 1px solid var(--light-grey--text);
  }
  &:last-of-type {
    border: none;
  }

  &:hover {
    background-color: var(--button--border--hover);

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
