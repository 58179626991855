.messageBox {
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 10px;
  margin-top: 20px;
  border-radius: 10px;
  align-items: center;

  @media (max-width: 767px) {
    .messageText {
      max-width: 90%;
      padding-right: 10px;
      word-wrap: break-word;
    }
  }
}

.backgroundUser {
  background: var(--message--background_GRADIENT);
}

.backgroundEdya {
  background: var(--message--background_GRADIENT_second);
}

.messageText {
  max-width: 90%;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  word-break: break-word;
  margin: 18px 5px 18px 0;
  color: var(--white--text--default);

  ul {
    margin: 15px 0 !important;
    list-style-type: circle;
  }

  ol {
    margin: 15px 0 !important;
    list-style-type: decimal;
  }

  li {
    left: 30px;
    position: relative;
    display: list-item;
    text-align: -webkit-match-parent;
  }

  @media (min-width: 1400px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 26px;
    line-height: 30px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 28px;
    line-height: 32px;
  }
}

.iconDialog {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  align-self: flex-start;
  margin: 15px 15px 15px 0;
  filter: drop-shadow(0px 0px 5px var(--shadow--default));

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
    margin: 18px 10px 18px 0;
  }

  @media (min-width: 1400px) {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 65px;
    height: 65px;
  }
}

.iconDialogEdya {
  width: 40px;
  align-self: flex-start;
  margin: 15px 15px 15px 0;
  filter: drop-shadow(0px 0px 5px var(--shadow--default));

  @media (max-width: 767px) {
    margin: 18px 10px 18px 0;
  }

  @media (min-width: 1400px) {
    width: 50px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 65px;
  }
}

.imageResponse {
  @media (max-width: 767px) {
    height: 50%;
    width: 100%;
  }
}

.copyButtonWrapper {
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
}
