.chats {
  width: 100%;
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}

.chat {
  display: flex;
  cursor: pointer;
  padding: 0 10px;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 400;
  min-height: 45px;
  line-height: 20px;
  align-items: center;
  color: var(--white--text--default);

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.iconChatMessage {
  margin-right: 7px;
  color: var(--white--text--default);
}

.iconChatEdit {
  margin-left: 7px;
  color: var(--light-grey--text);

  &:hover,
  &:active {
    color: var(--white--text--default);
  }
}

.iconChatDelete {
  margin-left: 10px;
  color: var(--light-grey--text);

  &:hover,
  &:active {
    color: var(--white--text--default);
  }
}

.titleChat {
  overflow: hidden;
  min-width: 130px;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1200px) {
    min-width: 100px;
  }
  @media (max-width: 1150px) {
    min-width: 170px;
  }
  @media (max-width: 767px) {
    min-width: 100px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    min-width: 180px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    min-width: 275px;
  }
}

.titleChatInput {
  padding: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  min-width: 138px;
  line-height: 20px;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: inherit;
  font-family: 'Montserrat', sans-serif;
  color: var(--white--text--default);

  &::placeholder {
    color: var(--white--text--default);
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
    border: none;
  }
}

.md {
  width: 230px;
  min-height: 45px;

  @media (max-width: 1200px) {
    width: 200px;
  }
  @media (max-width: 1150px) {
    width: 270px;
  }
  @media (max-width: 767px) {
    width: 200px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 300px;
    height: 60px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 400px;
    height: 70px;
  }
}

.setting {
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  border: 1px var(--border--gray--fifth) solid;
  background: var(--border--gray--fourth);

  &:hover {
    border: 1px var(--button--border--hover) solid;
    background-color: var(--menu--dark--background--opacity);
  }
}

.activeChat {
  border: 1px var(--button--border--hover) solid;
  background-color: var(--menu--dark--background--opacity);
}

.buttonWrap {
  top: 60px;
  right: 10px;
  z-index: 100;
  display: flex;
  position: absolute;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  text-transform: lowercase;
  background: var(--button--background--main);
}

.editButton {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid var(--light-grey--text);

  &:hover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--shadow--autho);
  }
}

.cancelButton {
  width: 100%;
  padding: 9px;

  &:hover {
    background-color: var(--shadow--autho);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
