.layout {
  width: 1174px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 745px;
  }
  @media screen and (max-width: 767px) {
    width: 310px;
  }
}
