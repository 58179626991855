.registration {
  display: flex;
  margin-top: 80px;
  align-items: center;
  justify-content: center;
}

.loginForm {
  width: 800px;
  padding: 50px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: var(--authorization--bg_GRADIENT);
  box-shadow: 0px 4px 20px var(--shadow--default);

  @media (max-width: 1200px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 310px;
    padding: 10px;
  }
}

.errorMessage {
  width: 400px;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: lowercase;
  color: var(--icon--box-shadow--red);

  @media (max-width: 767px) {
    width: 250px;
  }
}
