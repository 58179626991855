.basicTextBlock {
  width: 570px;
  display: flex;
  margin-top: 46px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey--text);

  @media screen and (max-width: 767px) {
    width: 300px;
  }
}

.basicText {
  border: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 7px 7px 0;
  text-transform: uppercase;
  background-color: inherit;
  color: var(--light-grey--text);

  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Montserrat', sans-serif;
}

.iconCheck {
  margin-left: 10px;
  color: var(--telemram--main);
}
