.settingUser {
  gap: 20px;
  display: flex;
  padding: 0 15px;
  height: 20%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 0;
  }

  @media (max-height: 500px) {
    margin: 5%;
  }
}

.userImg {
  width: 20px;
  height: 20px;
  margin: 0 13px 0 10px;

  @media (max-width: 1200px) {
    margin: 0 5px 0 8px;
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: 25px;
    height: 25px;
    margin: 0 6px 0 4px;
  }
}

.exitImg {
  width: 17px;
  height: 17px;
  margin: 0 14px 0 12px;

  @media (max-width: 1200px) {
    margin: 0 6px 0 10px;
  }
  @media (min-width: 1920px) and (min-height: 1080px) {
    width: 22px;
    height: 22px;
    margin: 0 6px 0 8px;
  }
}
