.prices {
  display: flex;
  flex-direction: column;
  color: var(--white--text--default);
}

.mainTitle {
  font-size: 50px;
  font-weight: 900;
  line-height: 61px;
  text-align: center;
  padding-top: 40px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--white--text);
  text-shadow: 0px 4px 15px var(--shadow--default);

  @media (max-width: 767px) {
    font-size: 40px;
  }
}

.pricesWrap {
  display: flex;
  align-items: start;
  margin-bottom: 40px;
  justify-content: space-evenly;

  @media screen and (max-width: 1200px) {
    gap: 30px;
    margin-bottom: 0;
    align-items: center;
    flex-direction: column;
  }
}

.priceCard {
  width: 360px;
  margin-top: 69px;
  border-radius: 10px;
  padding: 26px 18px 26px 24px;
  background: var(--price--card_GRADIENT);
  box-shadow: 0px 4px 20px var(--shadow--third);

  @media (max-width: 767px) {
    width: 310px;
  }
}

.cardTitle {
  display: flex;
  min-height: 105px;
  padding-bottom: 25px;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border--gray--default);
}

.priceTitle {
  font-size: 25px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.priceValue {
  font-size: 30px;
  font-weight: 800;
  margin-top: 12px;
  line-height: 37px;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  text-shadow: 0px 4px 15px var(--white--text--default);
}

.descriptionWrap {
  margin: 25px 0;
}

.advantages {
  font-size: 22px;
  margin-top: 21px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.advantage {
  display: flex;
  margin-top: 15px;
}

.iconPrice {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 4px 15px var(--icon--box-shadow--green);
}

.advantageText {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: lowercase;
}

.buttonWrapPrice {
  padding: 0 52px 0 46px;

  @media (max-width: 767px) {
    padding: 0 30px;
  }
}
