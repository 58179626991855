.inputWrap {
  position: relative;
}

.input {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 39px 15px 17px;
  background: var(--border--gray--opacity);
  border: 1px solid var(--light-grey--text);
  box-shadow: 0px 4px 15px var(--shadow--autho);

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: lowercase;
    color: var(--shadow--default);
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  &:focus,
  &:focus-visible {
    outline: 0;
    border: 1px var(--white--text--default) solid;
    box-shadow: 0px 4px 15px var(--border--gray--default);
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    width: 250px;
  }
}

.error {
  border: 1px var(--for-all--error) solid;
}

.errorMessage {
  width: 400px;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 17px;
  text-transform: lowercase;
  color: var(--icon--box-shadow--red);

  @media (max-width: 767px) {
    width: 250px;
  }
}

.iconEyase {
  top: 37px;
  right: 10px;
  position: absolute;
  color: var(--light-grey--text);
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: var(--white--text--default);
  }
}
