.logoEdya {
  width: 170px;
  height: 56px;
  filter: drop-shadow(0px 4px 20px var(--shadow--default));

  @media (max-height: 605px) {
    width: 150px;
    height: 51px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 200px;
    height: 67px;
  }
}

.declarationWrap {
  gap: 75px;
  height: 100%;
  display: flex;
  margin-top: 10px;
  text-align: center;
  align-items: center;

  @media (min-width: 1400px) {
    height: 80%;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    height: 100%;
  }

  @media (max-width: 1250px) {
    gap: 40px;
  }
  @media (max-width: 900px) {
    gap: 25px;
  }
  @media (max-width: 767px) {
    height: auto;
    margin-top: 30px;
    flex-direction: column;
  }
}

.examplesWrap {
  gap: 19px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.iconDeclaration {
  width: 42px;
  height: 43px;
  filter: drop-shadow(0px 4px 15px var(--white--text--default));

  @media (max-height: 605px) {
    width: 30px;
    height: 31px;
  }

  @media (min-width: 1400px) {
    width: 50px;
    height: 51px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 65px;
    height: 66px;
  }
}

.title {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  line-height: 20px;
  color: var(--white--text--default);

  @media (min-width: 1400px) {
    font-size: 20px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 24px;
  }
}

.example {
  width: 270px;
  height: 110px;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px var(--shadow--autho);
  background: var(--example--background_GRADIENT);

  @media (max-height: 605px) {
    height: 90px;
  }
  @media (max-width: 900px) {
    width: 235px;
  }
  @media (min-width: 1400px) {
    width: 300px;
  }
  @media (min-width: 1400px) and (min-height: 800px) {
    width: 300px;
    height: 150px;
  }
  @media (min-width: 1921px) and (min-height: 1081px) {
    width: 400px;
    height: 200px;
  }
  @media (min-width: 2561px) and (min-height: 1441px) {
    width: 500px;
    height: 250px;
  }
}

.textDeclaration {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;

  color: var(--light-grey--text);

  @media (max-height: 605px) {
    line-height: 16px;
  }

  @media (min-width: 1400px) {
    font-size: 18px;
  }

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 24px;
    line-height: 22px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 26px;
    line-height: 24px;
  }
}
