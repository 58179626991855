.authorization {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.aboutTitle {
  font-size: 50px;
  font-weight: 900;
  line-height: 61px;
  padding-top: 40px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--white--text);
  text-shadow: 0px 4px 15px var(--shadow--default);

  @media (max-width: 767px) {
    font-size: 40px;
  }
}

.aboutWrap {
  gap: 20px;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  padding-top: 65px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.01em;
  flex-direction: column;
  font-variant: small-caps;
  color: var(--white--text--default);

  @media (max-width: 1200px) {
    font-size: 22px;
    padding: 30px 50px 0;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    padding: 30px 0 30px;
  }
}

.question {
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  flex-direction: column;
  color: var(--white--text--default);

  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.loginForm {
  width: 800px;
  margin-top: 30px;
  padding: 50px;
  min-height: 400px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: var(--authorization--bg_GRADIENT);
  box-shadow: 0px 4px 20px var(--shadow--default);

  @media (max-width: 1200px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 310px;
    padding: 10px 10px 20px 10px;
  }
}

.formGroup {
  display: flex;
  align-items: center;
  margin: 17px 247px 0 10px;

  @media (max-width: 1200px) {
    margin: 17px 215px 0 10px;
  }
  @media (max-width: 767px) {
    margin: 17px 15px 0 10px;
  }
}

.inputAuto {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  padding: 15px 17px;
  border-radius: 10px;
  background: var(--border--gray--opacity);
  border: 1px solid var(--light-grey--text);
  box-shadow: 0px 4px 15px var(--shadow--autho);

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: lowercase;
    color: var(--shadow--default);
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  &:focus,
  &:focus-visible {
    outline: 0;
    border: 1px #f4f4f4 solid;
    box-shadow: 0px 4px 15px var(--border--gray--default);
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    width: 250px;
  }
}

.checkboxLabel {
  gap: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  align-items: center;
  text-transform: lowercase;
  color: var(--white--text--default);
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
}

.customCheckbox {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.customCheckbox {
  margin: 0;
  width: 11px;
  height: 11px;
  user-select: none;
  align-items: center;
  display: inline-flex;

  @media (max-width: 1200px) {
    width: 14px;
    height: 14px;
  }
}

.customCheckbox + label::before {
  width: 9px;
  height: 9px;
  content: '';
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 2px;
  display: inline-block;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid var(--border--gray--third);

  @media (max-width: 1200px) {
    width: 12px;
    height: 12px;
  }
}
.customCheckbox:checked + label::before {
  border-color: var(--custom--checkbox--blue);
  background-size: 70% 70%;
  background-color: var(--custom--checkbox--blue);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.customCheckbox:not(:disabled):not(:checked) + label:hover::before {
  border: 1px var(--white--text--default) solid;
  box-shadow: 0px 4px 15px var(--border--gray--default);
}
.customCheckbox:not(:disabled):active + label::before {
  border: 1px var(--white--text--default) solid;
  box-shadow: 0px 4px 15px var(--border--gray--default);
}
.customCheckbox:disabled + label::before {
  border: 1px var(--white--text--default) solid;
}

.errorMessage {
  width: 400px;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: lowercase;
  color: var(--icon--box-shadow--red);

  @media (max-width: 767px) {
    width: 250px;
  }
}

.remember {
  display: flex;
  justify-content: end;
  margin-top: 10px;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-top: 30px;
    justify-content: center;
  }
}

.rememberLink {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--light-grey--text);
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    transition: all 0.3s ease-in-out;
    color: var(--white--text--default);
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}
