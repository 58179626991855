.toastTitle {
  font-size: 20px;
  line-height: 24px;

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 26px;
    line-height: 30px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 28px;
    line-height: 32px;
  }
}

.toastMessage {
  font-size: 18px;
  line-height: 22px;

  @media (min-width: 1921px) and (min-height: 1081px) {
    font-size: 26px;
    line-height: 30px;
  }

  @media (min-width: 2561px) and (min-height: 1441px) {
    font-size: 28px;
    line-height: 32px;
  }
}

:root {
  --toastify-color-dark: var(--authorization--bg_GRADIENT);
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: var(--for-all--error);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--for-all--error);

  --toastify-toast-min-width: 450px;
  --toastify-toast-max-width: 750px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 75px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-dark: var(--white--text--default);

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
