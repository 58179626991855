.basicTextBlock {
  width: 570px;
  display: flex;
  margin-top: 46px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey--text);

  @media screen and (max-width: 767px) {
    width: 300px;
  }
}

.basicText {
  border: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 7px 7px 0;
  text-transform: uppercase;
  background-color: inherit;
  color: var(--light-grey--text);

  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: var(--light-grey--text);
  }
}
.basicInput {
  border: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 7px 7px 0;
  text-transform: none;
  background-color: inherit;
  color: var(--light-grey--text);

  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: var(--light-grey--text);
  }
}

.error {
  border: 1px var(--for-all--error) solid;
}

.errorMessage {
  width: 400px;
  font-size: 17px;
  margin-top: 5px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 17px;
  text-transform: lowercase;
  color: var(--icon--box-shadow--red);

  @media (max-width: 767px) {
    width: 250px;
  }
}

.iconEdit {
  margin-left: 10px;
  color: var(--light-grey--text);
  transition: all 0.3s ease-in-out;
}

.iconEyase {
  margin-left: 10px;
  color: var(--light-grey--text);
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: var(--white--text--default);
  }
}
